import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { debounce } from "utils/helpers";
import { TESTING, INFURA_API_KEY } from "utils/const";
import { handleScroll } from "./pointer";

let etherToUsdValue = null;
let web3Modal;

async function connectContractToWallet(contractRef) {
  console.log("connectContractToWallet()");
  console.log("Connecting contract to web3 provider which has a signer");

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: INFURA_API_KEY,
      },
    },
  };

  web3Modal = new Web3Modal({
    network: TESTING ? "rinkeby" : "mainnet",
    cacheProvider: false,
    providerOptions,
  });

  const provider = await web3Modal.connect();

  const ethersProvider = new ethers.providers.Web3Provider(provider);

  console.log("??? account", await ethersProvider.listAccounts());

  const signer = ethersProvider.getSigner();
  console.log("Signer", signer);
  contractRef.current = contractRef.current.connect(signer);
  console.log("Contract now is", contractRef.current);
}

function reconnectWalletIfPreviouslyConnected(contractRef, setCurrentAccount) {
  console.log("Checking if previously connected");
  const cachedProviderName = JSON.parse(
    localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"),
  );

  if (cachedProviderName) {
    console.log("Previously connected to", cachedProviderName);
    connectWallet(contractRef, setCurrentAccount);
  }
}

async function checkIfCorrectNetwork(contractRef) {
  const network = await contractRef.current.provider.getNetwork();

  console.log("Network name", network.name);

  if (TESTING && network.name !== "rinkeby")
    alert(`Please switch to rinkeby network and refresh the page.`);
  if (!TESTING && network.name !== "homestead")
    alert(`Please switch to mainnet and refresh the page.`);
}

async function connectWallet(contractRef, setCurrentAccount) {
  console.log("connectWallet()");

  await connectContractToWallet(contractRef);
  await checkIfCorrectNetwork(contractRef);

  setCurrentAccount(
    await contractRef.current.provider.getSigner().getAddress(),
  );

  return true;
}

async function disconnectWallet(contractRef, setCurrentAccount) {
  console.log("disconnectWallet()");

  web3Modal.clearCachedProvider();

  connectWallet(contractRef, setCurrentAccount);
}

async function etherToUsd() {
  if (etherToUsdValue === null) {
    const response = await fetch(
      "https://api.coinbase.com/v2/prices/ETH-USD/spot",
    );
    const json = await response.json();
    etherToUsdValue = json.data.amount;
  }

  return etherToUsdValue;
}

async function updateBalance(
  contractRef,
  setLifetimeBalance,
  setLifetimeBalanceUSD,
) {
  const newBalance = await contractRef.current.lifetimeBalance();
  const formattedEther = ethers.utils.formatEther(newBalance);

  setLifetimeBalance(formattedEther);

  const usdBalance = Math.round(
    parseFloat(formattedEther) * (await etherToUsd()),
  );

  setLifetimeBalanceUSD(`${usdBalance} USD`);
}

async function pay(
  contractRef,
  amount,
  name,
  url,
  setTxnHash,
  setName,
  setAmount,
  setUrl,
  currentAccount,
  setCurrentAccount,
  setLifetimeBalance,
  setLifetimeBalanceUSD,
  setIsProcessing,
) {
  if (!currentAccount) {
    console.log("No current account yet, connecting wallet");
    const wallet = await connectWallet(contractRef, setCurrentAccount);
    if (!wallet) return;
    console.log("Wallet is connected, lets go");
  }

  console.log("pay()", amount);
  const overrides = { value: ethers.utils.parseEther(amount) };
  const txn = await contractRef.current.pay(name, url, overrides);

  // Disable form
  setIsProcessing(true);

  setTxnHash(txn.hash);

  console.log("Mining...", txn.hash);

  await txn.wait();

  console.log("Mined -- ", txn.hash);

  setTxnHash(null);

  // Re-enable form
  setIsProcessing(false);

  // Clear all form inputs
  setName("");
  setUrl("");
  setAmount("");

  // Update total balance
  updateBalance(contractRef, setLifetimeBalance, setLifetimeBalanceUSD);
}

let debouncedHandleScroll = debounce(function () {
  handleScroll();
}, 5);

export {
  connectContractToWallet,
  reconnectWalletIfPreviouslyConnected,
  connectWallet,
  disconnectWallet,
  etherToUsd,
  updateBalance,
  pay,
  debouncedHandleScroll,
};
