import React from "react";
import "../App.css";
import "../About.css";

export default function About() {
  return (
    <div className="wrapper">
      <div className="crt"></div>

      <div className="screen">
        <div className="about">
          <h1>
            PAY GAME is pay-to-play taken to its logical conclusion. Whatever
            you pay, that's your score.
            <br />
            <br />
            It takes place on the Ethereum blockchain. It's verifiably fair and
            stored for eternity.
          </h1>

          <h2>Are there any prizes?</h2>
          <p>
            Yes. Everyone who participates gets a "COIN" NFT. You might be able
            to use these in future projects.
          </p>
          <p>
            there are also three NFT trophies for the top 3 players. However, if
            you lose your spot you lose your trophy. It automatically gets taken
            away and awarded to the new winner.
          </p>
          <p>
            Finally, there's a hall-of-fame NFT granted to anyone who ever
            reached the first place. It will get progressively harder to win
            this NFT, as beating the #1 spot will become more difficult over
            time.
          </p>
          <p>
            Anyone is also welcome to airdrop NFTs and tokens to the top
            players.
          </p>

          <h2>Who made this?</h2>
          <p>
            <a href="https://twitter.com/marckohlbrugge">I did.</a> It's my
            first web3 project. Let me know what you think.
          </p>

          <h2>This site looks familiar</h2>
          <p>
            You have a good memory. In 2016 I made{" "}
            <a href="https://highscore.money">Highscore.Money</a> which is PAY
            GAME's spiritual predecessor. I figured it would be even better
            on-chain so that's why I re-made it.
          </p>

          <h2>Where does the ETH go to?</h2>
          <p>To the mooooon!</p>

          <h2>That's not what I meant</h2>
          <p>
            Okay so the ETH initially goes to the contract, but I reserve the
            right to withdraw it to my personal wallet. If I make enough money
            off these little side projects, I can afford to spend more time to
            experiment with new ideas.
          </p>

          <h2>How did you make this?</h2>
          <p>
            A lot of trial and error. And help from friends. I recommend
            checking out these{" "}
            <a href="https://web3.career/learn-web3" target="_blank" rel="noopener noreferrer">
              web3 resources
            </a>{" "}
            if you want to make your own web3 project. You should also join{" "}
            <a href="https://wip.co">WIP</a> to meet other makers building cool
            stuff.
          </p>

          <h2>Feedback or suggestions?</h2>
          <p>
            <a href="https://twitter.com/marckohlbrugge">Send me a tweet.</a>{" "}
            I'll do my best answering as many as possible.
          </p>
        </div>
      </div>
    </div>
  );
}
