function requireEnv(name) {
  const value = process.env[name];
  if (value === undefined) {
    throw new Error(`Environment variable ${name} is not set`);
  }
  return value;
}

const COINS_PER_ETH = requireEnv('REACT_APP_COINS_PER_ETH');
const TESTING = requireEnv('REACT_APP_TESTING').toLowerCase() === 'true';
const INFURA_API_KEY = requireEnv('REACT_APP_INFURA_API_KEY');
const contractAddress = requireEnv('REACT_APP_CONTRACT_ADDRESS');
const openseaURL = requireEnv('REACT_APP_OPENSEA_URL');

module.exports = {
  COINS_PER_ETH,
  TESTING,
  INFURA_API_KEY,
  contractAddress,
  openseaURL,
};
