import { ethers } from "ethers";
import helpers from "utils/helpers";
import { playSound } from "./audio";
import { rainCoins } from "./animation";
import { TESTING } from "utils/const";

export function testPlayer(name, score) {
  score = ethers.constants.WeiPerEther.div(100).mul(score);

  return {
    addr: `foobar${Math.random()}`,
    name: name,
    url: "",
    formattedScore: `${(+ethers.utils.formatEther(score)).toFixed(2)} ETH`,
    score: score,
    etherscan: "",
  };
}

export async function getAllPlayers(
  contractRef,
  setAllPlayers,
  audio,
  setCoins,
  updateBalance,
  setLifetimeBalance,
  setLifetimeBalanceUSD,
) {
  let playerAddresses = await contractRef.current.getPlayerAddresses();
  let players = await contractRef.current.getPlayers();
  // console.log({players});
  let formattedPlayers = {};

  // console.log("getting all players", currentAccount);

  players.forEach((player, index) => {
    formattedPlayers[playerAddresses[index].toLowerCase()] = formatPlayer(
      player,
      playerAddresses[index],
    );
  });
  // console.log({formattedPlayers});

  // Create dummy players for debugging
  // Array.from(Array(10)).forEach((_, i) => {
  //   formattedPlayers[`test${i}`] = testPlayer(`Example ${i}`, i + 1);
  // });

  setAllPlayers(formattedPlayers);

  contractRef.current.on("NewPayment", (address, player, value) => {
    console.log("NewPayment", address, player, value);

    updateBalance(contractRef, setLifetimeBalance, setLifetimeBalanceUSD);

    playSound(audio);
    rainCoins(value, setCoins);

    addOrUpdatePlayer(player, address, true, setAllPlayers, players);

    // Unset flicker
    setTimeout(
      () => addOrUpdatePlayer(player, address, false, setAllPlayers, players),
      10000,
    );
  });
}

export function addOrUpdatePlayer(
  player,
  address,
  flicker = false,
  setAllPlayers,
  allPlayers,
) {
  const formattedPlayer = formatPlayer(player, address, flicker);

  setAllPlayers((prevAllPlayers, props) => ({
    ...prevAllPlayers,
    [address.toLowerCase()]: formattedPlayer,
  }));
}

export function formatPlayer(player, address, flicker = false) {
  console.log("Formatting player", player);
  return {
    addr: address.toLowerCase(),
    name: player.name,
    formattedName: ethers.utils.parseBytes32String(player.name),
    url: player.url,
    formattedScore: `${(+ethers.utils.formatEther(player.score)).toFixed(
      2,
    )} ETH`,
    score: player.score,
    etherscan: helpers.etherscanUrl(address, TESTING),
    flicker: flicker,
    lastPayment: player.lastPayment,
  };
}

function playersSortedByScoreAndRanked(allPlayers) {
  // Sort by score
  const playersSortedByScore = Object.values(allPlayers).sort(
    (a, b) => b.score.sub(a.score) || a.lastPayment.sub(b.lastPayment),
  );

  // Add rank
  return playersSortedByScore.map((player, index) => {
    return { ...player, rank: index + 1 };
  });
}

function playersSortedByTimeStampAndRanked(allPlayers) {
  // Ensure we get the ranks
  return playersSortedByScoreAndRanked(allPlayers).sort((a, b) =>
    b.lastPayment.sub(a.lastPayment),
  );
}

export function playersSortedAndRanked(allPlayers, mode) {
  if (mode === "score") {
    return playersSortedByScoreAndRanked(allPlayers);
  } else {
    return playersSortedByTimeStampAndRanked(allPlayers);
  }
}
