import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      {/* <a href={helpers.etherscanUrl(contractAddress, TESTING)}>View contract</a> */}
      <a href="https://x.com/marckohlbrugge">Made by @marckohlbrugge</a>
      <a href="https://marc.io">
        Get notified of next drop
      </a>
    </div>
  );
}
